//This function is for Regular (universal) search
export const trackSearchEventMain = (() => {
  return (data) => {
    //Get total results, sum of results for each vertical
    let numEntries = 0;
    const dataKeys = Object.keys(data.resultsCountByVertical)
    dataKeys.forEach(key => {
      numEntries += data.resultsCountByVertical[key].totalResultsCount
    })

    // All results for main search are on 1st page, pagination only enabled for verticals
    let cPage = 1;

    //Get url of answers iframe location
    let hostName = getHostName(data.queryString);

    const searchObj = {
      "eVar11": data.queryString,
      "prop12": numEntries,
      "prop13": 'all:' + cPage,
      "prop18": hostName
    }

    let matchesQuery = matchPrevQuery(data.queryString);
    if (!matchesQuery) {
      searchObj.events = 'event11';
    }
    s_dtm.trackData(searchObj, "tl_o");
  }
})()

//This function is for Updated (vertical and paginated) searches
export const trackSearchEventVertical = (() => {
  return (data) => {
    let numEntries = 0;
    numEntries = data.resultsCount;

    //Add pagination info if it exists, is not default to 1
    let cPage = 1;
    const paginationEl = document.querySelector('#js-answersPagination')
    if (paginationEl) {
      //Pull the current page from data of pagination previous link
      const firstEl = paginationEl.querySelector('.js-yxt-Pagination-first')
      if (firstEl) {
        cPage = JSON.parse(firstEl.dataset.eventoptions).currentPage
      }
    }

    //Get the info of any applied filters
    let filters = ""

    //Check if filters object exists
    if(ANSWERS.core.storage.get("facetFilters")) {
      const filterObj = ANSWERS.core.storage.get("facetFilters")
      const filterKeys = Object.keys(ANSWERS.core.storage.get("facetFilters"))
      //Should only be one key for each vertical, but it is in an array object
      filterKeys.forEach(key => {
        const filterArray = filterObj[key]
        if (filterArray.length > 0) {
          filters += "category: "
        }
        filterArray.forEach((item, index) => {
          const filterItem = item[key]["$eq"]
          filters += filterItem 
          if (index !== filterArray.length - 1) {
            filters += ' and '
          }
        })
      })
    }

    //Append the current vertical to the current page
    if(ANSWERS.core.storage.get("search-config").verticalKey) {
      let cKey = ANSWERS.core.storage.get("search-config").verticalKey
      if (cKey == 'Links') {
        cPage = 'content:' + cPage
      } else {
        cPage = cKey + ':' + cPage
      }
    }

    //Get url of answers iframe location
    let hostName = getHostName(data.queryString);

    const searchObj = {
      "eVar11": data.queryString,
      "prop12": numEntries,
      "prop13": cPage,
      "prop18": hostName,
      "prop19": filters
    }

    let matchesQuery = matchPrevQuery(data.queryString);
    if (!matchesQuery) {
      searchObj.events = 'event11';
    }
    s_dtm.trackData(searchObj, "tl_o");
  }
})()

//This function is for all search results clicked analytics
export function cardClickEvent(cardEl) {
  const titleLink = cardEl.querySelector('.HitchhikerProductStandard-titleLink')
  if (titleLink) {
    titleLink.addEventListener('click', () => {
      cardClickHelper(titleLink, cardEl);
    });
  }

  const ctaEl = cardEl.querySelector('.js-HitchhikerCTA')
  if (ctaEl) {
    ctaEl.addEventListener('click', () => {
      cardClickHelper(ctaEl, cardEl);
    });
  }

  const videoTitleLink = cardEl.querySelector('.HitchhikerProductProminentImage-titleLink');
  if (videoTitleLink) {
    videoTitleLink.addEventListener('click', () => {
      cardClickHelper(videoTitleLink, cardEl);
    });
  }

  const contentTitleLink = cardEl.querySelector('.HitchhikerLinkStandard-titleLink');
  if (contentTitleLink) {
    contentTitleLink.addEventListener('click', () => {
      cardClickHelper(contentTitleLink, cardEl);
    });
  }
}

//This is a helper function for the search results analytics function above
export function cardClickHelper(linkEl, cardEl) {
  let cIndex = 0;
  let linkAddr = linkEl.href;

  //Calculate number of current link within returned list
  if (document.querySelector('.js-answersUniversalResults')) {
    //If in universal search
    const answersWrapper = document.querySelector('.js-answersUniversalResults');
    const cardArray = answersWrapper.querySelectorAll('.yxt-Card-child')
    for (let card of cardArray) {
      cIndex += 1;
      if (card == cardEl) {
        break
      }
    }
  } else {

    //If in vertical search get index from data object of cards parent node
    cIndex = JSON.parse(cardEl.parentElement.dataset.opts)._index + 1;
  }

  const trackingStr = cIndex + "^" + linkAddr;
  const jsonObj = {
    "eVar13": trackingStr,
    "events": "event12=" + cIndex + ',event13'
  }
  s_dtm.trackData(jsonObj, "tl_o")
}

//This function is for all promoted results analytics
export function promoTracker(promoEl) {
  const titleLink = promoEl.querySelector('.HitchhikerJobStandard-titleLink')
  if (titleLink) {
    titleLink.addEventListener('click', () => {
      const jsonObj = {
        "eVar18": titleLink.href
      }
      s_dtm.trackData(jsonObj, "tl_o")
    });
  }

  const ctaEl = promoEl.querySelector('.js-HitchhikerCTA')
  if (ctaEl) {
    ctaEl.addEventListener('click', () => {
      const jsonObj = {
        "eVar18": ctaEl.href
      }
      s_dtm.trackData(jsonObj, "tl_o")
    });
  }
}

function getHostName(searchTerm) {
  let hostName;
  const host = window.url;

  if (host && host.includes('helpcenter')) {
    hostName = 'help center';
  } else {
    hostName = 'main site';
  }

  return hostName
}

function matchPrevQuery(searchTerm) {
  const host = document.referrer;
  //Update search term to match endocing NOTE: not typical URI encoding

  searchTerm = encodeURIComponent(searchTerm)
  let updatedSearchTerm = searchTerm.replaceAll('%20', '+');
  updatedSearchTerm.replaceAll('(', '%28');
  updatedSearchTerm.replaceAll(')', '%29');

  let matches = false;
  if (host) {
    const seperatedURL = host.split("&");
    seperatedURL.forEach(item => {
      item = '' + item;
      if (item.includes("query=")) {
        const charNum = item.indexOf("query=");
        const queryParam = item.substring(charNum + 6)
        if (queryParam === updatedSearchTerm) {
          matches = true;
        }
      }
    })
  }
  return matches;
}
